import Enum from './enum'

class AccountPaidStatus extends Enum {
    // 1:未払 5:トライアル 2:支払済 3:継続課金失敗 4:永久無料
    static PENDING = 1; // 未払
    static TRIAL = 5; // トライアル
    static PAID = 2; // 支払済
    static FAILED = 3; // 継続課金失敗
    static FREE = 4; // 永久無料

    static values() {
        return {
            [this.PENDING]: '未払',
            [this.TRIAL]: 'トライアル',
            [this.PAID]: '支払済',
            [this.FAILED]: '継続課金失敗',
            [this.FREE]: '永久無料',
        }
    }
}

export default AccountPaidStatus;
