import Role from '@/shared/models/enums/role'
import Account from '@/shared/models/entities/account'

export const auth = {
    namespaced: true,
    state: {
        account: null,
        user_name: null,
        email: null,
        role: null,
        access_token: null,
        access_token_expired: null,
        access_token_issued: null,
        refresh_token: null,
        refresh_token_expired: null,
        refresh_token_issued: null,
        is_signed_in: false,
    },
    mutations: {
        setAccessToken (state, param) {
            state.account = new Account(param.account);
            state.user_name = param.user_name;
            state.email = param.email;
            state.role = param.role;
            state.access_token = param.access_token;
            state.access_token_expired = param.access_token_expired;
            state.access_token_issued = param.access_token_issued;
            state.refresh_token = param.refresh_token;
            state.refresh_token_expired = param.refresh_token_expired;
            state.refresh_token_issued = param.refresh_token_issued;
            state.is_signed_in = true;
        },
        signOut (state) {
            state.account = null;
            state.user_name = null;
            state.email = null;
            state.role = null;
            state.access_token = null;
            state.access_token_expired = null;
            state.access_token_issued = null;
            state.refresh_token = null;
            state.refresh_token_expired = null;
            state.refresh_token_issued = null;
            state.is_signed_in = false;
        },
        setAccount (state, param) {
            state.account = new Account(param);
        },
    },
    getters: {
        /*
        |-----------------------------
        | 1:契約管理者 2:管理者 権限あり
        |-----------------------------
        |
        */
        // 自分以外の登録したSKUを見れる
        canViewAllSku: (state) => () => {
            return (state.role === Role.OWNER || state.role === Role.MANAGER);
        },
        // SKUのAmazon連携保留状態の解除
        canReleaseSku: (state) => () => {
            return (state.role === Role.OWNER || state.role === Role.MANAGER);
        },
        // Amazon連携エラーになったものの再試行
        canRetryError: (state) => () => {
            return (state.role === Role.OWNER || state.role === Role.MANAGER);
        },
        // 自分以外のユーザ別出品履歴の集計を見れる
        canViewStats: (state) => () => {
            return (state.role === Role.OWNER || state.role === Role.MANAGER);
        },
        // ユーザ登録・編集
        canManageUser: (state) => () => {
            return (state.role === Role.OWNER || state.role === Role.MANAGER);
        },

        // 出品設定（デフォルト設定、コンディションノート登録・更新・削除）
        canManageSettingInventory: (state) => () => {
            return (state.role === Role.OWNER || state.role === Role.MANAGER);
        },
        // 出品禁止ASIN
        canManageForbiddenAsin: (state) => () => {
            return (state.role === Role.OWNER || state.role === Role.MANAGER);
        },
        // 出品禁止メーカー
        canManageForbiddenMaker: (state) => () => {
            return (state.role === Role.OWNER || state.role === Role.MANAGER);
        },
        // 除外出品者
        canManageIgnoredDealer: (state) => () => {
            return (state.role === Role.OWNER || state.role === Role.MANAGER);
        },
        // 除外キーワード
        canManageIgnoredKeyword: (state) => () => {
            return (state.role === Role.OWNER || state.role === Role.MANAGER);
        },

        /*
        |-----------------------------
        | 1:契約管理者 権限あり
        |-----------------------------
        |
        */
        // SP-API設定
        canManageSpapi: (state) => () => {
            return (state.role === Role.OWNER);
        },
        // 支払い設定（カード変更など）
        canManagePayment: (state) => () => {
            return (state.role === Role.OWNER);
        },
    }
}
