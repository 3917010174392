<template>
    <div class="screen-loader-wrap">
        <div class="screen-loader">
            <div></div>
            <div></div>
        </div>
    </div>
</template>

<script>
export default {
    name: 'ScreenLoader',
}
</script>

<style scoped>
.screen-loader-wrap {
    position: fixed;
    top: 0;
    left: 0;
    z-index: 9999;
    width: 100%;
    height: 100%;
    background: rgba(255, 255, 255, 0.5);
}
.screen-loader {
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    width: 30px;
    height: 30px;
    margin: auto;
}

.screen-loader > div {
    -webkit-animation-fill-mode: both;
    animation-fill-mode: both;
    position: absolute;
    top: 0px;
    left: 0px;
    border-radius: 100%;
}

.screen-loader > div:first-child {
    background: #1ab394;
    height: 16px;
    width: 16px;
    top: 7px;
    left: -7px;
    -webkit-animation: scale 1s 0s cubic-bezier(0.09, 0.57, 0.49, 0.9) infinite;
    animation: scale 1s 0s cubic-bezier(0.09, 0.57, 0.49, 0.9) infinite;
}

.screen-loader > div:last-child {
    position: absolute;
    border: 2px solid #1ab394;
    width: 33px;
    height: 33px;
    left: -16px;
    top: -2px;
    background: transparent;
    border: 2px solid;
    border-color: #1ab394 transparent #1ab394 transparent;
    -webkit-animation: rotate 1s 0s cubic-bezier(0.09, 0.57, 0.49, 0.9) infinite;
    animation: rotate 1s 0s cubic-bezier(0.09, 0.57, 0.49, 0.9) infinite;
    -webkit-animation-duration: 1s;
    animation-duration: 1s;
}

@keyframes rotate {
    0% {
        -webkit-transform: rotate(0deg) scale(1);
        transform: rotate(0deg) scale(1);
    }
    50% {
        -webkit-transform: rotate(180deg) scale(0.6);
        transform: rotate(180deg) scale(0.6);
    }
    100% {
        -webkit-transform: rotate(360deg) scale(1);
        transform: rotate(360deg) scale(1);
    }
}
@keyframes scale {
  0% {
    -webkit-transform: scale(1);
            transform: scale(1);
    opacity: 1; }
  45% {
    -webkit-transform: scale(0.1);
            transform: scale(0.1);
    opacity: 0.7; }
  80% {
    -webkit-transform: scale(1);
            transform: scale(1);
    opacity: 1; } }
</style>
