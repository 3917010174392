<template>
    <section class="section">
        <h1><i class="bi bi-lock-fill text-primary"></i> ログイン</h1>

        <form class="form" @submit.prevent="signin()">
            <div class="form-row align-items-end mb-3">
                <div class="form-group col-md-6">
                    <label>メールアドレス</label>
                    <input type="text" class="form-control" required="required" v-model="email">
                </div>
            </div>
            <div class="form-row align-items-end mb-3">
                <div class="form-group col-md-6">
                    <label>パスワード</label>
                    <input type="password" class="form-control" required="required" v-model="password">
                </div>
            </div>
            <div class="form-row">
                <button-exec-edit text="ログイン" />
            </div>
            <div class="mt-5">
                <router-link :to="{name: 'PageReminder'}">パスワードを忘れた方</router-link>
            </div>
        </form>
    </section>
</template>

<script>
import axios from 'axios';
import ButtonExecEdit from '@/shared/components/design/buttons/ButtonExecEdit.vue';

export default {
    components: {
        ButtonExecEdit,
    },
    name: 'PageSignin',
    inject: [
        'startScreenLoading',
        'endScreenLoading',
        'showErrorMessage',
        'fetchUsers',
        'fetchErrorCount',
    ],
    data() {
        return {
            email: null,
            password: null
        }
    },
    mounted() {
        // ログインしていたらPageHomeへリダイレクト
        if (this.$store.state.user.auth.is_signed_in) {
            this.$router.push({name: 'PageHome'});
        }
    },
    methods: {
        signin() {
            this.startScreenLoading();
            axios.post(process.env.VUE_APP_API_BASE_URI + '/signin', {
                email: this.email,
                password: this.password
            })
            .then((response) => {
                this.$store.commit('user/auth/setAccessToken', {
                    account: response.data.account,
                    user_name: response.data.user_name,
                    email: response.data.email,
                    role: response.data.role,
                    access_token: response.data.access_token,
                    access_token_expired: response.data.access_token_expired,
                    access_token_issued: response.data.access_token_issued,
                    refresh_token: response.data.refresh_token,
                    refresh_token_expired: response.data.refresh_token_expired,
                    refresh_token_issued: response.data.refresh_token_issued,
                });

                //サイドメニュー用
                this.fetchUsers();
                this.fetchErrorCount();

                if (this.$route.query.redirect) {
                    this.$router.push(this.$route.query.redirect);
                } else {
                    this.$router.push('/');
                }
            })
            .catch((error) => {
                //※ここは $http 使っていないので、自力でメッセージ表示が必要
                this.showErrorMessage(error.response.data.message);
            })
            .finally(() => {
                this.endScreenLoading();
            });
        },
    }
}
</script>

<style scoped>

</style>
