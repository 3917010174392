<template>
    <template v-if="$store.state.user.auth.is_signed_in">
        <div class="layout-wrap">
            <div class="layout-aside">
                <div class="aside-header bg-primary">
                    <router-link :to="{name: 'PageHome'}" class="aside-header-branding">ResaleTrap v3</router-link>
                    <button class="aside-header-toggle"><i class="bi bi-list"></i></button>
                </div>
                <div class="p-2" v-if="hasError()">
                    <div class="alert alert-danger p-2 mb-0">
                        <div>連携エラーあり</div>
                        <div><router-link :to="{ name: 'PageInventory', query: {sync_status_group: 300} }" class="ms-2 text-danger text-decoration-none"><i class="bi bi-search"></i> 監視中 {{ count_error_active_display }} 件</router-link></div>
                        <div><router-link :to="{ name: 'PageInventoryClosed', query: {sync_status_group: 300} }" class="ms-2 text-danger text-decoration-none"><i class="bi bi-folder"></i> 終了 {{ count_error_ended_display }} 件</router-link></div>
                        <div>({{ count_error_cached_display }} 更新)</div>
                    </div>
                </div>
                <nav>
                    <ul class="nav-list">
                        <li class="nav-item" :class="{'bg-warning': hasNewInfo()}">
                            <router-link :to="{ name: 'PageHome' }" :class="{'text-danger': hasNewInfo()}"><i class="bi bi-chat-right-text"></i> お知らせ</router-link>
                        </li>
                        <li class="nav-item nav-dropdown" :class="{active: isActive('inventory', ['inventory/closed'])}">
                            <router-link :to="{ name: 'PageInventory' }"><i class="bi bi-search"></i> 監視中の商品</router-link>
                            <ul class="nav-dropdown-list">
                                <li class="nav-item"><router-link :to="{ name: 'PageInventory' }">全て</router-link></li>
                                <template v-if="$store.getters['user/auth/canViewAllSku']()">
                                <li class="nav-item" v-for="user of users" :key="user.user_id">
                                    <router-link :to="{ name: 'PageInventory', params: {user: user.user_id} }">{{ user.user_name }}</router-link>
                                </li>
                                </template>
                            </ul>
                        </li>
                        <li class="nav-item nav-dropdown" :class="{active: isActive('inventory/closed')}">
                            <router-link :to="{ name: 'PageInventoryClosed' }"><i class="bi bi-folder"></i> 終了した商品</router-link>
                            <ul class="nav-dropdown-list">
                                <li class="nav-item"><router-link :to="{ name: 'PageInventoryClosed' }">全て</router-link></li>
                                <template v-if="$store.getters['user/auth/canViewAllSku']()">
                                <li class="nav-item" v-for="user of users" :key="user.user_id">
                                    <router-link :to="{ name: 'PageInventoryClosed', params: {user: user.user_id} }">{{ user.user_name }}</router-link>
                                </li>
                                </template>
                            </ul>
                        </li>
                        <li class="nav-item" :class="{active: isActive('entry')}">
                            <router-link :to="{ name: 'PageEntry' }"><i class="bi bi-box-seam"></i> 出品</router-link>
                        </li>
                        <li class="nav-item" :class="{active: isActive('stats')}">
                            <router-link :to="{ name: 'PageStats' }"><i class="bi bi-bar-chart-fill"></i> 集計</router-link>
                        </li>
                        <li class="nav-item nav-dropdown" :class="{active: isActive('setting')}" v-if="$store.getters['user/auth/canManageSettingInventory']()">
                            <router-link :to="{ name: 'PageSettingInventory' }"><i class="bi bi-gear"></i> 設定</router-link>
                            <ul class="nav-dropdown-list">
                                <li class="nav-item" v-if="$store.getters['user/auth/canManageSettingInventory']()">
                                    <router-link :to="{ name: 'PageSettingInventory' }">出品設定</router-link>
                                </li>
                                <li class="nav-item" v-if="$store.getters['user/auth/canManageForbiddenAsin']()">
                                    <router-link :to="{ name: 'PageSettingForbiddenAsin' }">出品禁止ASIN</router-link>
                                </li>
                                <li class="nav-item" v-if="$store.getters['user/auth/canManageForbiddenMaker']()">
                                    <router-link :to="{ name: 'PageSettingForbiddenMaker' }">出品禁止メーカー</router-link>
                                </li>
                                <li class="nav-item" v-if="$store.getters['user/auth/canManageIgnoredDealer']()">
                                    <router-link :to="{ name: 'PageSettingIgnoredDealer' }">除外出品者</router-link>
                                </li>
                                <li class="nav-item" v-if="$store.getters['user/auth/canManageIgnoredKeyword']()">
                                    <router-link :to="{ name: 'PageSettingIgnoredKeyword' }">除外キーワード</router-link>
                                </li>
                                <li class="nav-item" v-if="$store.getters['user/auth/canManageSpapi']()">
                                    <router-link :to="{ name: 'PageSettingSpapi' }">SP-API</router-link>
                                </li>
                            </ul>
                        </li>
                        <li class="nav-item" :class="{active: isActive('manage/user')}" v-if="$store.getters['user/auth/canManageUser']()">
                            <router-link :to="{ name: 'PageManageUser' }"><i class="bi bi-people-fill"></i> ユーザ管理</router-link>
                        </li>
                        <li class="nav-item" :class="{active: isActive('payment')}" v-if="$store.getters['user/auth/canManagePayment']()">
                            <router-link :to="{ name: 'PagePayment' }"><i class="bi bi-credit-card"></i> お支払い</router-link>
                        </li>
                        <li class="nav-item" :class="{active: isActive('preference')}">
                            <router-link :to="{ name: 'PagePreference' }"><i class="bi bi-person-circle"></i> アカウント</router-link>
                        </li>
                        <li class="nav-item"><a href="#" @click="signOut()"><i class="bi bi-door-open"></i> ログアウト</a></li>
                        <li class="nav-item">
                            <a href="https://docs.google.com/forms/d/1dNmXRRnLL1qnnGGEVuYQZZCNySGBkpkONSHxlX-sA_c/viewform" target="_blank">
                                <i class="bi bi-envelope"></i> お問合せ
                            </a>
                        </li>
                        <!-- 契約管理者のみ解約可 -->
                        <li class="nav-item" v-if="$store.state.user.auth.role === Role.OWNER">
                            <a href="https://docs.google.com/forms/d/e/1FAIpQLSch_mPEXuiARyQsmf-44KUTo1KXx_MLToS3i2eOe5p-kXy5QQ/viewform" target="_blank">
                                <i class="bi bi-slash-circle"></i> 解約
                            </a>
                        </li>
                    </ul>
                </nav>
            </div>
            <div class="layout-main">
                <div class="container">
                    <router-view/>
                </div>
                <footer>
                    <router-link :to="{ name: 'PageNotation' }">特定商取引法に基づく表記</router-link>
                    <router-link :to="{ name: 'PagePrivacy' }">プライバシーポリシー</router-link>
                </footer>
            </div>
        </div>

    </template>

    <template v-else>
        <nav class="navbar navbar-dark bg-dark text-white mb-3">
            <div class="container-fluid">
                ResaleTrap v3
            </div>
        </nav>

        <div class="container container-guest">
            <router-view/>
        </div>
        <footer>
            <router-link :to="{ name: 'PageNotation' }">特定商取引法に基づく表記</router-link>
            <router-link :to="{ name: 'PagePrivacy' }">プライバシーポリシー</router-link>
        </footer>
    </template>

    <screen-loader v-if="loading > 0"></screen-loader>

    <message-dialog ref="message_dialog" :title="dialog.title" :message="dialog.message" :icon="dialog.icon"></message-dialog>
</template>

<script>
import ScreenLoader from '@/shared/components/tool/ScreenLoader.vue'
import MessageDialog from '@/shared/components/tool/MessageDialog.vue'
import User from '@/shared/models/entities/user'
import Role from '@/shared/models/enums/role'
import { dateFormat } from '@/shared/utilities/date-format';

export default {
    name: 'App',
    components: {
        ScreenLoader,
        MessageDialog
    },
    provide() {
        // 参考 emit/props vs provide/inject
        // https://cloudsmith.co.jp/blog/frontend/2020/12/1656030.html
        return {
            startScreenLoading: this.startScreenLoading,
            endScreenLoading: this.endScreenLoading,
            quitScreenLoading: this.quitScreenLoading,
            showMessage: this.showMessage,
            showErrorMessage: this.showErrorMessage,
            fetchUsers: this.fetchUsers,
            fetchErrorCount: this.fetchErrorCount,
            hasError: this.hasError,
        }
    },
    data() {
        return {
            loading: 0,
            dialog: {
                title: null,
                message: null,
                icon: null,
            },
            nav_collapse: false,
            users: [],
            count_error_active: 0,
            count_error_ended: 0,
            count_error_cached: null,
            Role,
        }
    },
    mounted() {
        window.addEventListener('show_message', (event) => {
            this.showMessage(event.detail.title, event.detail.message);
        });
        window.addEventListener('show_error_message', (event) => {
            this.showErrorMessage(event.detail.title, event.detail.message);
        });

        if (this.$store.state.user.auth.is_signed_in) {
            this.fetchUsers();
        }

        this.fetchErrorCount();
        setInterval(() => {
            this.fetchErrorCount();
        }, 60000); // 1分ごと
    },
    methods: {
        fetchUsers() {
            this.$http.get('/users')
            .then(response => {
                this.users = response.data.map((row) => new User(row));
            })
        },
        fetchErrorCount() {
            if (!this.$store.state.user.auth.is_signed_in) {
                return;
            }
            this.$http.get('/errors')
            .then(response => {
                this.count_error_active = response.data.active;
                this.count_error_ended = response.data.ended;
                this.count_error_cached = response.data.cached;
            })
        },
        hasError() {
            return (this.count_error_active > 0 || this.count_error_ended > 0)
        },
        signOut() {
            this.$store.commit('user/auth/signOut');
            this.$router.push({ name: 'PageSignin' });
        },
        startScreenLoading() {
            this.loading++;
        },
        endScreenLoading() {
            this.loading--;
        },
        quitScreenLoading() {
            this.loading = 0;
        },
        showMessage(title, message) {
            this.dialog.title = title ?? '完了';
            this.dialog.message = message;
            this.dialog.icon = 'success';
            this.$refs.message_dialog.show();
        },
        showErrorMessage(title, message) {
            this.dialog.title = title ?? 'エラー';
            this.dialog.message = message;
            this.dialog.icon = 'error';
            this.$refs.message_dialog.show();
        },
        isActive(key, excepts) {
            if (Array.isArray(excepts)) {
                for (let except of excepts) {
                    if (this.$route.path.indexOf('/' + except) === 0) {
                        return false;
                    }
                }
            }
            return (this.$route.path.indexOf('/' + key) === 0);
        },
        hasNewInfo() {
            return this.$helper.today() < '2022-10-04';
        },
   },
   computed: {
        user_name() {
            return this.$store.state.user.auth.user_name;
        },
        count_error_active_display() {
            return (this.count_error_active < 100 ? this.count_error_active : '99+');
        },
        count_error_ended_display() {
            return (this.count_error_ended < 100 ? this.count_error_ended : '99+');
        },
        count_error_cached_display() {
            return dateFormat('H:i', this.count_error_cached);
        },
    }
}
</script>

<style>
.container {
    padding-top: 1em;
    padding-bottom: 1em;
    min-height: calc(100vh - 2rem);
}
.container-guest {
    min-height: calc(100vh - 5.5rem);
}
.layout-wrap {
    display: flex;
    justify-content: space-between;
}
.layout-aside {
    width: 200px;
    min-height: 100vh;
    background: #2f4050;
}
.layout-main {
    width: calc(100% - 200px);
}

.aside-header {
    display: flex;
}
.aside-header-branding {
    width: calc(100% - 40px);
    margin-bottom: 0;
    font-size: 1rem;
    padding: 0.8rem;
    color: #FFF;
    text-decoration: none;
}
.aside-header-branding:hover {
    color: #FFF;
}
.aside-header-toggle {
    width: 40px;
    border: none;
    background: transparent;
    font-size: 1.5rem;
    color: #FFF;
    font-weight: bold;
}
.nav-list {
    margin: 0;
    padding: 0;
    list-style-type: none;
}
.nav-dropdown-list {
    display: none;
    margin: 0;
    padding-left: 1rem;
    list-style-type: none;
}
.active .nav-dropdown-list {
    display: block;
}
.nav-item {
    border-left: 3px solid #2f4050;
}
.nav-item.active {
    border-left: 3px solid var(--bs-primary);
}
.nav-item a,
.nav-item span {
    display: block;
    padding: 0.8rem;
    font-size: 0.8rem;
    font-weight: bold;
    color: #a7b1c2;
    text-decoration: none;
}
footer {
    background: #DDD;
    padding: 0.5rem 1rem;
    text-align: right;
}
footer a {
    display: inline-block;
    margin-left: 1rem;
    color: #000;
}
</style>
