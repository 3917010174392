import { isNud } from '@/shared/utilities/typing'
import AccountIsActive from '../enums/account-is-active';
import AccountPaidStatus from '../enums/account-paid-status';
import OwnerUser from './owner-user';

/**
 * アカウント エンティティ
 */
class Account {
    account_id;
    account_name;
    is_active;
    paid_status;
    payjp_customer_id;
    has_advanced_extension;
    parent_account_id;
    spapi_merchant_id;
    spapi_access_token;
    spapi_error_count;
    spapi_error_latest_datetime;
    default_lead_time;
    default_item_condition;
    default_sub_condition;
    default_profit_ratio;
    default_shipping_fee;
    default_shipping_group;

    constructor(properties) {
        if (isNud(properties)) {
            properties = {};
        }

        this.account_id = properties.account_id;
        this.account_name = properties.account_name;
        this.is_active = properties.is_active;
        this.paid_status = properties.paid_status;
        this.payjp_customer_id = properties.payjp_customer_id;
        this.has_advanced_extension = properties.has_advanced_extension;
        this.parent_account_id = properties.parent_account_id;
        this.spapi_merchant_id = properties.spapi_merchant_id;
        this.spapi_access_token = properties.spapi_access_token;
        this.spapi_error_count = properties.spapi_error_count;
        this.spapi_error_latest_datetime = properties.spapi_error_latest_datetime;
        this.default_lead_time = properties.default_lead_time;
        this.default_item_condition = properties.default_item_condition;
        this.default_sub_condition = properties.default_sub_condition;
        this.default_profit_ratio = properties.default_profit_ratio;
        this.default_shipping_fee = properties.default_shipping_fee;
        this.default_shipping_group = properties.default_shipping_group;

        this.owner_user = new OwnerUser(properties.owner_user);
    }

    get is_active_display() {
        return AccountIsActive.get(this.is_active);
    }

    get paid_status_display() {
        return AccountPaidStatus.get(this.paid_status);
    }
}

export default Account;
